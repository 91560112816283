@import '../../../../../../styles/basics';

.bb-cta-dual-overlay {
  width: 100%;
  background-color: $white;
  padding-bottom: 40px;

  // Mobile-only gradient.
  &__image-container {
    position: relative;
  }

  // Image for larger screens only.
  &__image-md {
    display: none;
  }

  &__gradient {
    height: 51px;
    top: 90px;
    background-image: linear-gradient(to bottom, transparent, $white);
    position: absolute;
    width: 100%;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: $line-height-large;

    @include media-breakpoint-up(md) {
      font-size: 28px;
      line-height: $line-height-normal;
    }
  }

  img {
    max-height: 140px; // Compensating for smaller margins vs design.
    object-fit: cover;
    width: 100%;
  }

  &__contents {
    h3 {
      padding-right: 10px;
      margin-bottom: 20px;
    }
  }

  &__button {
    margin: 0 !important;
  }

  @include media-breakpoint-up(md) {
    cursor: pointer;
    width: 420px;

    &-mobile {
      display: none;
    }

    // Default image.
    &__image {
      display: none;
    }

    &__image-md {
      display: block;
    }

    &__gradient {
      display: none;
    }

    &:first-child {
        margin-right: 80px;
    }

    img {
      max-height: none;
      // Fix the slight displacement of the image by the containing <a>.
      margin-top: -1px;
    }

    &__contents {
      padding: 0 20px;

      h3 {
        padding-right: 0;
        margin-top: 20px;
      }
    }
  }
}
