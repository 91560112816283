@import '../../../../styles/basics';

.cta {
  position: relative;
  overflow: hidden;
  min-height: 330px;

  @media (min-width: 421px) {
    min-height: 400px;
  }

  :global(.container) {
    :global(.overlay) {
      [data-component^="button"] {
        margin: 0;
      }
    }
  }
}
