@import '../../../../../styles/basics';

.bb-cta-dual {
  // Desktop-only gradient.
  &__gradient {
    display: none;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    background-image: linear-gradient(181deg, rgba(33, 36, 49, 0.3), rgba(33, 36, 49, 0.7));
  }

  &__image {
    display: none;
    height: 700px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__container {
    > a {
      text-decoration: none;
    }
  }

  @include media-breakpoint-down(md) {
    @include bb-standalone-spacing();
  }

  @include media-breakpoint-up(md) {
    &__gradient {
      display: block;
    }

    &__image {
      display: block;
    }

    &__container {
      display: flex;
      justify-content: center;
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      left: 50%;
      top: 50%;
    }
  }
}
